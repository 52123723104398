<template>
  <div>
    <div class="image">
      <img src="../../assets/img/access-denied.svg" />
    </div>
    <div class="text-muted mx-3">
      <h3 class="header-message">{{ $t("ACCESS_DENIED.HEADER") }}</h3>
      <div class="message-1">{{ $t("ACCESS_DENIED.MESSAGE_LINE_1") }}</div>
      <div class="message-2">{{ $t("ACCESS_DENIED.MESSAGE_LINE_2") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessDenied",
};
</script>
<style scoped>
.image {
  margin-left: 44%;
  margin-top: 10%;
  margin-bottom: 2%;
}
.header-message {
  margin-left: 45%;
}
.message-1 {
  margin-left: 38%;
}
.message-2 {
  margin-left: 40%;
}
</style>